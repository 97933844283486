<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
      <b-col>
        <h4><b-link :to="{ name: 'AppObligations' }">Back to data loading</b-link></h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-for="row in config" :key="row.key">
          <b-form-group v-if="row.options" :label-for="row.key" :label="row.label" label-cols-lg="2" content-cols-lg="8" label-align-lg="right">
            <b-form-select v-model="$data[row.selected]" :options="$data[row.options]" :id="row.key"></b-form-select>
          </b-form-group>
        </div>
        <b-form-group label-cols-lg="2" content-cols-lg="8">
          <b-btn class="mr-2" @click="clearAll">clear filters</b-btn>
          <b-btn v-b-toggle.columnsSidebar>manage columns</b-btn>
          <b-sidebar id="columnsSidebar" title="Columns" shadow>
            <div class="p-4">
              <b-form-checkbox-group
                id="checkbox-columns"
                v-model="columnsSelected"
                :options="columnsOptions"
                name="flavour-1"
                stacked
              ></b-form-checkbox-group>
            </div>
          </b-sidebar>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h4>
          <b-link :to="{ name: 'AppObligationsStart' }">Table</b-link> |
          <b-link :to="{ name: 'AppObligationsDashboard' }">Dashboard</b-link>
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash"

function buildConfig () {
  const data = [
    { key: 'ref', sortable: true, label: 'Id', field: true, removableColumn: true },
    { key: 'theme', sortable: true, label: 'Theme', options: true, field: true, removableColumn: true },
    { key: "regulation", sortable: true, field: true, label: "Regulation", removableColumn: true },
    { key: "regulator", sortable: true, label: "Regulator", removableColumn: true },
    { key: "jurisdiction", sortable: true, label: "Jurisdiction", options: true, field: true },
    { key: "chapter", sortable: true, label: "Chapter", field: true, removableColumn: true },
    { key: "paragraph", label: 'Paragraph', sortable: true, field: true, removableColumn: true },
    { key: "regulatory_text", sortable: true, label: 'Text', field: true, removableColumn: true },
    { key: "rule_type", label: "Rule Type", sortable: true, options: true, field: true, removableColumn: true },
    { key: "requirements_plain_english_translation", label: "Plain English", sortable: true, field: false, removableColumn: true },
    { key: "rule_owner", label: "Rule Owner", sortable: true, options: true, field: false, removableColumn: true },
    { key: "workstream", label: "Workstream", sortable: true, options: true, field: true, removableColumn: true },
    { key: "in_scope_for_gap_analysis", label: "In Scope for Gap", sortable: true, options: true, field: true, removableColumn: true },
    { key: "compliance_level", label: "Compliance Level", sortable: true, options: true, field: true, removableColumn: true },
    { key: "comparison_to_peers", label: "Comparison to Peers", sortable: true, options: true, field: true, removableColumn: true }
  ]
  _.each(data, (row) => {
    if (row.options) {
      row.selected = row.key + "Selected"
      row.options = row.key + "Options"
    }
  })
  return data
}

const config = buildConfig()

function buildFields () {
  const fields = []
  _.each(config, (row) => {
    if (row.field) {
      fields.push(row)
    }
  })
  return fields
}

function buildColumnsOptions () {
  const options = []
  _.each(config, (row) => {
    if (row.removableColumn) {
      options.push({ text: row.label, value: row.key })
    }
  })
  return options
}

export default {
  name: "ObligationsAppMain",
  components: {},
  computed: {
    dataFiltered: function () {
      let data = this.data
      // filter data for each option:true field
      _.each(config, (row) => {
        if (row.options) {
          if (this[row.selected]) {
            data = _.filter(data, (d) => {
              return d[row.key] === this[row.selected]
            })
          }
        }
      })
      return data
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Obligations app main', action: 'open Obligations app main page' })
    const recordsRaw = localStorage.getItem('frmcloudObligationsApp')
    const data = JSON.parse(recordsRaw)
    this.data = data
    _.each(data[0], (value, key) => {
      this.dataArrays[key] = []
    })
    _.each(data, (row) => {
      _.each(row, (value, key) => {
        this.dataArrays[key].push(value)
      })
    })
    this.dataArraysUniq = _.mapValues(this.dataArrays, (row) => {
      return _.uniq(row).sort()
    })
    // build options for drop-downs
    _.each(config, (row) => {
      if (row.options) {
        _.each(this.dataArraysUniq[row.key], (v) => {
          this[row.options].push({ value: v, text: v })
        })
      }
    })
    // identify which columns are selected
    _.each(this.columnsOptions, option => {
      if (_.find(this.fields, ['key', option.value])) {
        this.columnsSelected.push(option.value)
      }
    })
    this.$store.commit('setAppObligationsFields', this.fields)
  },
  data () {
    const data = {
      config: buildConfig(),
      columnsOptions: buildColumnsOptions(),
      columnsSelected: [],
      data: [],
      dataArrays: {},
      dataArraysUniq: {},
      fields: buildFields()
    }
    // build data fields for selected and options
    _.each(config, (row) => {
      if (row.options) {
        data[row.selected] = null
        data[row.options] = [{ value: null, text: "select " + row.label }]
      }
    })
    return data
  },
  methods: {
    clearAll: function () {
      _.each(config, (row) => {
        if (row.options) {
          this.$data[row.selected] = null
        }
      })
    }
  },
  watch: {
    columnsSelected: function () {
      _.each(this.columnsOptions, option => {
        if (this.columnsSelected.includes(option.value)) {
          if (!_.find(this.fields, ['key', option.value])) {
            this.fields.push(_.find(config, ['key', option.value]))
          }
        } else {
          if (_.find(this.fields, ['key', option.value])) {
            _.remove(this.fields, ['key', option.value])
            this.fields.push('1')
            this.fields.pop()
          }
        }
      })
      this.$store.commit('setAppObligationsFields', this.fields)
    },
    dataFiltered: function () {
      this.$store.commit('setAppObligationsData', this.dataFiltered)
    }
  }
}
</script>

<style>
</style>
